var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Model, Server } from 'miragejs';
import minimongo from 'minimongo';
import defaultData from './defaultData.json';
import { login, getPublicKey, resetPassword, checkUserNameIsUnique, passwordResetTokenValidate, updateMyPassword, logout, getAccountSetup, putAccountSetup, getUserContexts, putUserContexts, getCmsSettings, getAuthSchemes, getPasswordPolicy, } from './handlers/authentication';
import { getUserProfile, putUserProfile } from './handlers/userProfile';
import { getTheme } from './handlers/branding';
import { listApis, getApi, listApiPermissions, getApiSpecContent, } from './handlers/apis';
import { listApiGroups, getApiGroup, getApiGroupApis, getApiGroupEula, } from './handlers/apiGroups';
import { listApiPlans, getApiPlan, getApiApiPlanAssociation, getApiPlansFeatureFlag, } from './handlers/apiPlans';
import { listAccountPlans, getAccountPlan } from './handlers/accountPlans';
import { listApplications, getApplication, postApplication, getGenerateSharedSecret, getSecretHashMetadata, deleteApplication, } from './handlers/applications';
import { listApiKeys } from './handlers/apiKeys';
import { listOrganizations, getOrganization, postOrganization, putOrganization, deleteOrganization, } from './handlers/organizations';
import { getDocumentsTree, postDocument, putDocument, getDocument, putDocumentsTree, deleteDocument, } from './handlers/documents';
import { listApiTags, listTags } from './handlers/tags';
import { listApiAssets } from './handlers/assets';
import { promisify } from './promisify';
import { deleteCurrentUser } from './handlers/currentUser';
import { postRegistration } from './handlers/registrations';
import { initializeRunningIndicator } from './running/indicator';
import { getMetricsHits, getMetricsLatency } from './handlers/metrics';
import { listCustomFields } from './handlers/customFields';
import { getApiEula } from './handlers/apiEulas';
var database;
var DefaultUrlPrefix = 'https://apim.dev.ca.com/';
/**
 * Starts a Mirage Server mocking all the ApiHub APIs. This server runs in the browser and intercepts
 * all requests made to the /api routes
 *
 * @param {options} Options The options
 * @param {options.data} data The default data used to populate the mock server
 * @param {options.urlPrefix} urlPrefix The base url for running the mock server
 * @param {options.showRunningIndicator} showRunningIndicator Show or hide the running indicator
 * @param {options.timing} timing The delay before responding
 */
export var startApiHubMockedServer = function (_a) {
    if (_a === void 0) { _a = {
        timing: 150,
    }; }
    return __awaiter(void 0, void 0, void 0, function () {
        var server;
        var _b = _a.data, data = _b === void 0 ? defaultData : _b, _c = _a.urlPrefix, urlPrefix = _c === void 0 ? DefaultUrlPrefix : _c, _d = _a.showRunningIndicator, showRunningIndicator = _d === void 0 ? true : _d, _e = _a.runningIndicatorLink, runningIndicatorLink = _e === void 0 ? '#' : _e, options = __rest(_a, ["data", "urlPrefix", "showRunningIndicator", "runningIndicatorLink"]);
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    if (showRunningIndicator) {
                        initializeRunningIndicator({ link: runningIndicatorLink });
                    }
                    return [4 /*yield*/, getDatabase()];
                case 1:
                    database = _f.sent();
                    return [4 /*yield*/, initDatabase(database, data)];
                case 2:
                    _f.sent();
                    server = new Server({
                        models: {
                            userContexts: Model.extend(),
                            apis: Model.extend(),
                            applications: Model.extend(),
                            documents: Model.extend(),
                        },
                        routes: function () {
                            var _this = this;
                            this.get(urlPrefix + "api/apim/GenerateNewSharedSecret", getGenerateSharedSecret(database), options);
                            this.get(urlPrefix + "api/apim/passwordResetTokenValidate", passwordResetTokenValidate(database), options);
                            this.put(urlPrefix + "api/apim/v2/users/password/reset/:uuid", updateMyPassword(database), options);
                            this.get(urlPrefix + "api/apim/ResetMyPassword()", resetPassword(database), options);
                            this.get(urlPrefix + "api/apim/logout", logout(database), options);
                            this.post(urlPrefix + "api/apim/authenticate/login", login(database), options);
                            this.get(urlPrefix + "api/apim/UserNameUnique()", checkUserNameIsUnique(database), options);
                            this.get(urlPrefix + "api/apim/accountSetup", getAccountSetup(database), options);
                            this.put(urlPrefix + "api/apim/accountSetup", putAccountSetup(database), options);
                            this.get(urlPrefix + "api/apim/authenticate/getPublicKey", getPublicKey(database), options);
                            this.get(urlPrefix + "api/apim/public/auth/schemes/passwordpolicy", getPasswordPolicy(database), options);
                            this.get(urlPrefix + "api/apim/userContexts", getUserContexts(database), options);
                            this.put(urlPrefix + "api/apim/userContexts", putUserContexts(database), options);
                            this.get(urlPrefix + "api/apim/userProfile", getUserProfile(database), options);
                            this.put(urlPrefix + "api/apim/userProfile", putUserProfile(database), options);
                            this.get(urlPrefix + "api/apim/branding/1.0/themes", getTheme(database), options);
                            this.get(urlPrefix + "api/apim/api-management/1.0/apis", listApis(database), options);
                            this.get(urlPrefix + "api/apim/api-management/1.0/applications", listApplications(database), options);
                            this.post(urlPrefix + "api/apim/Applications", postApplication(database), options);
                            this.get(urlPrefix + "api/apim/api-management/1.0/applications/:applicationUuid/api-keys", listApiKeys(database), options);
                            this.get(urlPrefix + "api/apim/api-management/1.0/account-plans", listAccountPlans(database), options);
                            this.get(urlPrefix + "api/apim/api-management/1.0/account-plans/:id", getAccountPlan(database), options);
                            this.get(urlPrefix + "api/apim/document-service/1.0/docs/:type/:typeUuid/:navtitle", getDocument(database), options);
                            this.post(urlPrefix + "api/apim/document-service/1.0/docs/:type/:typeUuid/:navtitle", postDocument(database), options);
                            this.put(urlPrefix + "api/apim/document-service/1.0/docs/:type/:typeUuid/:navtitle", putDocument(database), options);
                            this.del(urlPrefix + "api/apim/document-service/1.0/docs/:type/:typeUuid/:navtitle", deleteDocument(database), options);
                            this.get(urlPrefix + "api/apim/document-service/1.0/docs/:type/:typeUuid", getDocumentsTree(database), options);
                            this.put(urlPrefix + "api/apim/document-service/1.0/docs/:type/:typeUuid", putDocumentsTree(database), options);
                            this.get(urlPrefix + "api/apim/api-management/1.0/apis/:id", getApi(database), options);
                            this.get(urlPrefix + "api/apim/api-management/1.0/apis/:id/tags", listApiTags(database), options);
                            this.get(urlPrefix + "tenant-admin/internal/organizations", listOrganizations(database), options);
                            this.get(urlPrefix + "api/apim/api-management/1.0/organizations/:id", getOrganization(database), options);
                            this.get(urlPrefix + "api/apim/api-management/1.0/apis/:id/assets", listApiAssets(database), options);
                            this.get(urlPrefix + "api/apim/tags", listTags(database), options);
                            this.get(urlPrefix + "api/apim/api-management/internal/permissions/apis/:id/permitted", listApiPermissions(database), options);
                            this.get(urlPrefix + "api/apim/2.0/Apis", function (schema, request) { return __awaiter(_this, void 0, void 0, function () {
                                var results;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, listApis(database)(schema, request)];
                                        case 1:
                                            results = (_a.sent()).results;
                                            return [2 /*return*/, results];
                                    }
                                });
                            }); }, options);
                            this.get(urlPrefix + "api/apim/api-management/internal/OrganizationApiGroups", listApiGroups(database), options);
                            this.get(urlPrefix + "api/apim/api-management/1.0/api-groups/:id", getApiGroup(database), options);
                            this.get(urlPrefix + "api/apim/api-management/1.0/api-groups/:id/apis", getApiGroupApis(database), options);
                            this.get(urlPrefix + "api/apim/api-management/internal/ApiGroups/:id/Eulas", getApiGroupEula(database), options);
                            this.get(urlPrefix + "api/apim/api-management/internal/api-plans", listApiPlans(database), options);
                            this.get(urlPrefix + "api/apim/api-management/1.0/api-plans/:id", getApiPlan(database), options);
                            this.get(urlPrefix + "api/apim/api-management/internal/api-plans", getApiApiPlanAssociation(database), options);
                            this.get(urlPrefix + "api/apim/Settings('FEATURE_FLAG_API_PLANS')", getApiPlansFeatureFlag(database), options);
                            this.get(urlPrefix + "api/apim/Settings('APP_SECRET_HASHING_METADATA')", getSecretHashMetadata(database), options);
                            this.delete(urlPrefix + "api/apim/Applications(':uuid')", deleteApplication(database), options);
                            // This is the only way I found to make the SpecContent route work.
                            // Its url looks like api/apim/2.0/Apis(':id')/SpecContent.
                            // It seems either the parenthesises or the quotes make the route parameter
                            // parsing fail.
                            this.get(urlPrefix + "api/apim/2.0/*path", function (schema, request) { return __awaiter(_this, void 0, void 0, function () {
                                var path, uuid;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            path = request.params.path;
                                            if (!path.match(/Apis\('.*'\)\/SpecContent/)) return [3 /*break*/, 2];
                                            return [4 /*yield*/, getApiSpecContent(database)(schema, request)];
                                        case 1: return [2 /*return*/, _a.sent()];
                                        case 2:
                                            if (!path.match(/Apis\('.*'\)/)) return [3 /*break*/, 4];
                                            uuid = path.substring(6, path.length - 13);
                                            return [4 /*yield*/, getApi(database)(schema, {
                                                    params: {
                                                        uuid: uuid,
                                                    },
                                                })];
                                        case 3: return [2 /*return*/, _a.sent()];
                                        case 4: return [2 /*return*/, {}];
                                    }
                                });
                            }); }, options);
                            this.post(urlPrefix + "api/apim/Registrations", postRegistration(database), options);
                            this.get(urlPrefix + "api/apim/cmsSettings", getCmsSettings(database), options);
                            this.get(urlPrefix + "api/apim/public/auth/schemes", getAuthSchemes(database), options);
                            // api/apim/analytics/metrics/v1/hits/apis/by-week
                            this.get(urlPrefix + "api/apim/analytics/metrics/v1/hits/apis/:timeAggregation", getMetricsHits(database), options);
                            this.get(urlPrefix + "api/apim/analytics/metrics/v1/latency/apis/:timeAggregation", getMetricsLatency(database), options);
                            this.get(urlPrefix + "api/apim/CustomFields", listCustomFields(database), options);
                            // This is the only way I found to make the legacy routes work.
                            // Its url looks api/apim/api-management/1.0/Applications(':uuid').
                            // It seems either the parenthesises or the quotes make the route parameter
                            // parsing fail.
                            this.get(urlPrefix + "api/apim/*path", function (schema, request) { return __awaiter(_this, void 0, void 0, function () {
                                var path, uuid;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            console.log('GET - Catch all', request.url);
                                            path = request.params.path;
                                            if (!path.match(/ApiEulas\('.*'\)/)) return [3 /*break*/, 2];
                                            return [4 /*yield*/, getApiEula(database)(schema, request)];
                                        case 1: return [2 /*return*/, _a.sent()];
                                        case 2:
                                            if (!path.match(/Applications\('.*'\)/)) return [3 /*break*/, 4];
                                            uuid = path.substring(14, path.length - 2);
                                            return [4 /*yield*/, getApplication(database)(schema, {
                                                    params: {
                                                        uuid: uuid,
                                                    },
                                                })];
                                        case 3: return [2 /*return*/, _a.sent()];
                                        case 4: return [2 /*return*/, {}];
                                    }
                                });
                            }); }, options);
                            this.put(urlPrefix + "api/apim/*path", function (schema, request) { return __awaiter(_this, void 0, void 0, function () {
                                var path, uuid;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            console.log('PUT - Catch all', request.params.path);
                                            path = request.params.path;
                                            if (!path.match(/Organizations\('.*'\)/)) return [3 /*break*/, 2];
                                            uuid = path.substring(15, path.length - 2);
                                            return [4 /*yield*/, putOrganization(database)(schema, {
                                                    params: {
                                                        uuid: uuid,
                                                    },
                                                })];
                                        case 1: return [2 /*return*/, _a.sent()];
                                        case 2: return [2 /*return*/, {}];
                                    }
                                });
                            }); }, options);
                            this.post(urlPrefix + "api/apim/*path", function (schema, request) { return __awaiter(_this, void 0, void 0, function () {
                                var path, uuid;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            console.log('POST - Catch all', request.params.path);
                                            path = request.params.path;
                                            if (!path.match(/Organizations\('.*'\)/)) return [3 /*break*/, 2];
                                            uuid = path.substring(15, path.length - 2);
                                            return [4 /*yield*/, postOrganization(database)(schema, {
                                                    params: {
                                                        uuid: uuid,
                                                    },
                                                })];
                                        case 1: return [2 /*return*/, _a.sent()];
                                        case 2: return [2 /*return*/, {}];
                                    }
                                });
                            }); }, options);
                            this.delete(urlPrefix + "api/apim/*path", function (schema, request) { return __awaiter(_this, void 0, void 0, function () {
                                var path, uuid;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            console.log('DELETE - Catch all', request.params.path);
                                            path = request.params.path;
                                            if (!path.match(/Organizations\('.*'\)/)) return [3 /*break*/, 2];
                                            uuid = path.substring(15, path.length - 2);
                                            return [4 /*yield*/, deleteOrganization(database)(schema, {
                                                    params: {
                                                        uuid: uuid,
                                                    },
                                                })];
                                        case 1: return [2 /*return*/, _a.sent()];
                                        case 2: return [2 /*return*/, {}];
                                    }
                                });
                            }); }, options);
                            this.passthrough();
                        },
                    });
                    window.Layer7Mock = {
                        database: database,
                        clearDatabase: function () {
                            return clearDatabase();
                        },
                        initDatabase: function (initialData) {
                            if (initialData === void 0) { initialData = data; }
                            return initDatabase(database, initialData);
                        },
                        resetDatabase: function (initialData) {
                            var _this = this;
                            if (initialData === void 0) { initialData = data; }
                            return this.clearDatabase().then(function () {
                                return _this.initDatabase(initialData);
                            });
                        },
                        deleteCurrentUser: deleteCurrentUser,
                    };
                    return [2 /*return*/, { server: server, database: database }];
            }
        });
    });
};
function getDatabase() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (database) {
                return [2 /*return*/, database];
            }
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    var indexedDb = new minimongo.IndexedDb({ namespace: 'layer7' }, function () { return resolve(indexedDb); }, reject);
                })];
        });
    });
}
function clearDatabase() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Promise.all([
                        promisify(database.removeCollection.bind(database), 'apis'),
                        promisify(database.removeCollection.bind(database), 'apiEulas'),
                        promisify(database.removeCollection.bind(database), 'apiGroups'),
                        promisify(database.removeCollection.bind(database), 'apiKeys'),
                        promisify(database.removeCollection.bind(database), 'apiPlans'),
                        promisify(database.removeCollection.bind(database), 'applications'),
                        promisify(database.removeCollection.bind(database), 'userContexts'),
                        promisify(database.removeCollection.bind(database), 'documents'),
                        promisify(database.removeCollection.bind(database), 'specs'),
                        promisify(database.removeCollection.bind(database), 'assets'),
                        promisify(database.removeCollection.bind(database), 'tags'),
                        promisify(database.removeCollection.bind(database), 'registrations'),
                        promisify(database.removeCollection.bind(database), 'customFields'),
                    ])];
                case 1:
                    _a.sent();
                    console.log('Mock database cleared');
                    return [2 /*return*/];
            }
        });
    });
}
function initDatabase(db, initialData) {
    if (initialData === void 0) { initialData = defaultData; }
    return __awaiter(this, void 0, void 0, function () {
        var hasData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Promise.all([
                        promisify(db.addCollection.bind(db), 'apis'),
                        promisify(db.addCollection.bind(db), 'apiEulas'),
                        promisify(db.addCollection.bind(db), 'apiGroups'),
                        promisify(db.addCollection.bind(db), 'apiKeys'),
                        promisify(db.addCollection.bind(db), 'apiPlans'),
                        promisify(db.addCollection.bind(db), 'applications'),
                        promisify(db.addCollection.bind(db), 'userContexts'),
                        promisify(db.addCollection.bind(db), 'documents'),
                        promisify(db.addCollection.bind(db), 'specs'),
                        promisify(db.addCollection.bind(db), 'assets'),
                        promisify(db.addCollection.bind(db), 'tags'),
                        promisify(db.addCollection.bind(db), 'registrations'),
                        promisify(db.addCollection.bind(db), 'customFields'),
                    ])];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, promisify(db.apis.find().fetch)];
                case 2:
                    hasData = (_a.sent()).length > 0;
                    if (!!hasData) return [3 /*break*/, 4];
                    return [4 /*yield*/, Promise.all([
                            promisify(db.tags.upsert.bind(db.tags), initialData.tags),
                            promisify(db.apis.upsert.bind(db.apis), initialData.apis),
                            promisify(db.registrations.upsert.bind(db.registrations), initialData.registrations),
                            promisify(db.applications.upsert.bind(db.applications), initialData.applications),
                            promisify(db.apiGroups.upsert.bind(db.apiGroups), initialData.apiGroups),
                            promisify(db.apiGroups.upsert.bind(db.apiKeys), initialData.apiKeys),
                            promisify(db.apiPlans.upsert.bind(db.apiPlans), initialData.apiPlans),
                            promisify(db.userContexts.upsert.bind(db.userContexts), initialData.userContexts),
                            promisify(db.documents.upsert.bind(db.documents), initialData.documents),
                            promisify(db.assets.upsert.bind(db.assets), initialData.assets),
                            promisify(db.customFields.upsert.bind(db.customFields), initialData.customFields),
                            promisify(db.apiEulas.upsert.bind(db.apiEulas), initialData.apiEulas),
                        ])];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    console.log('Mock database initialized');
                    return [2 /*return*/];
            }
        });
    });
}
