var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { promisify } from '../promisify';
import faker from 'faker';
export function listApplications(database) {
    var _this = this;
    return function (schema, request) { return __awaiter(_this, void 0, void 0, function () {
        var _a, page, size, order, sort, apiUuid, name, $select, filter, finalPage, finalSize, _b, finalSort, finalOrder, finalFilter, totalElements, results, applications;
        var _c;
        var _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = request.queryParams, page = _a.page, size = _a.size, order = _a.order, sort = _a.sort, apiUuid = _a.apiUuid, name = _a.name, $select = _a.$select, filter = __rest(_a, ["page", "size", "order", "sort", "apiUuid", "name", "$select"]);
                    finalPage = parseInt(request.queryParams.page, 10);
                    finalSize = parseInt(request.queryParams.size, 10);
                    _b = (_d = request.queryParams.sort) === null || _d === void 0 ? void 0 : _d.split(','), finalSort = _b[0], finalOrder = _b[1];
                    finalFilter = __assign({}, filter);
                    if (apiUuid) {
                        finalFilter._accessibleApis = apiUuid;
                    }
                    return [4 /*yield*/, promisify(database.applications.find(finalFilter).fetch)];
                case 1:
                    totalElements = (_e.sent()).length;
                    return [4 /*yield*/, promisify(database.applications.find(finalFilter, {
                            limit: finalSize,
                            skip: finalSize * finalPage,
                            sort: (_c = {},
                                _c[finalSort] = (finalOrder === null || finalOrder === void 0 ? void 0 : finalOrder.toLowerCase()) === 'asc' ? 1 : -1,
                                _c),
                        }).fetch)];
                case 2:
                    results = _e.sent();
                    applications = results.map(function (r) {
                        var result = {};
                        var keys = Object.keys(r);
                        keys.map(function (k) {
                            result[k.toLowerCase()] = r[k];
                        });
                        return result;
                    });
                    return [2 /*return*/, {
                            results: applications,
                            totalElements: totalElements,
                        }];
            }
        });
    }); };
}
export function getApplication(database) {
    var _this = this;
    return function (schema, request) { return __awaiter(_this, void 0, void 0, function () {
        var application, keys;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, promisify(database.applications.findOne.bind(database.applications), { uuid: request.params.uuid }, {})];
                case 1:
                    application = _a.sent();
                    keys = Object.keys(application);
                    keys.map(function (k) {
                        application["" + k[0].toUpperCase() + k.slice(1)] = application[k];
                    });
                    return [2 /*return*/, application];
            }
        });
    }); };
}
export function getGenerateSharedSecret(database) {
    return function (schema, request) {
        return {
            d: {
                result: faker.random.alphaNumeric(32),
            },
        };
        // To test Error Scenario
        // return new Response(405, undefined, {
        //     error : {
        //         code : "MethodNotAllowedException",
        //         message : {
        //             lang : "en-US",
        //             value : "Method Not Allowed"
        //         }
        //     }
        // });
    };
}
export function getSecretHashMetadata(database) {
    return {
        Uuid: faker.random.uuid(),
        Name: 'APP_SECRET_HASHING_METADATA',
        Value: '{"algorithm":"SHA-512","plaintextAllowed":true}',
    };
}
export function postApplication(database) {
    var _this = this;
    return function (schema, request) { return __awaiter(_this, void 0, void 0, function () {
        var uuid, application;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    uuid = faker.random.uuid();
                    application = __assign(__assign({}, JSON.parse(request.requestBody)), { Uuid: uuid, uuid: uuid, id: uuid, status: 'Enabled', Status: 'Enabled' });
                    return [4 /*yield*/, promisify(database.applications.upsert.bind(database.applications), __assign({}, application))];
                case 1:
                    _a.sent();
                    return [2 /*return*/, application];
            }
        });
    }); };
}
export function deleteApplication(database) {
    var _this = this;
    return function (schema, request) { return __awaiter(_this, void 0, void 0, function () {
        var uuid, application;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    uuid = request.params.uuid;
                    return [4 /*yield*/, promisify(database.applications.findOne.bind(database.applications), { uuid: uuid }, {})];
                case 1:
                    application = _a.sent();
                    return [4 /*yield*/, promisify(database.applications.remove.bind(database.applications), application._id)];
                case 2:
                    _a.sent();
                    return [2 /*return*/, application];
            }
        });
    }); };
}
